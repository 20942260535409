<template>
  <div>
    <div v-if="!isDetails">
      <div class="card" style="height: 40px">
        <div style="flex: 0; display: flex; float: left">
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" style="width" @click="handleTransfer"
              >转让会员</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" style="width" @click="handleAssistMember"
              >添加协助</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" style="width" @click="handleBackCommon"
              >放回公海</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" @click="setIntegral">积分设置</el-button>
          </div>
          <div style="width: 60px; height: 40px; margin-right: 60px">
            <el-button type="primary" @click="setSavings">储值金充值</el-button>
          </div>
          <div style="width: 60px; height: 40px; margin-right: 60px">
            <Send :client_user_master_ids="client_user_master_ids"   :client_user_ids="client_user_id" />
          </div>
          <div style="width: 70px; height: 40px; margin-right: 25px">
            <el-button
              type="primary"
              plain
              icon="el-icon-upload2"
              :loading="exportLoading"
              @click="handleExport()"
              >导出</el-button
            >
          </div>
        </div>
        <div style="float: right; display: flex">
          <SearchConf function_code="clientuser" @setSearch="handleSetSearch" />
          <TableConf function_code="clientuser" @onRowList="handleRowList" />
          <ExportConf function_code="clientuser" />
        </div>
      </div>
      <div
        style="
          padding: 10px 0;
          border: 1px solid #e8e8e8;
          border-top: none;
          background: white;
          border-radius: 0 0 5px 5px;
        "
      >
        <Search @onCond="handleCond" function_code="clientuser_assignuser" />
      </div>
      <div
        class="table"
        style="border-top: 1px solid #e8e8e8; margin-top: 15px"
        v-if="rowList.length !== 0"
      >
        <el-table
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          height="650"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
          @row-dblclick="handleDalclick"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <template v-for="(item, index) in rowList">
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
            >
              <template slot-scope="scope">
                <div>
                  <div v-if="item.field_alias === 'str_tag_name'">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row[item.field_alias]"
                      placement="top"
                    >
                      <div
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ scope.row[item.field_alias] }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-else-if="item.field_alias === 'realname'">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row[item.field_alias]"
                      placement="top"
                    >
                      <div
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ scope.row[item.field_alias] }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-else>
                    {{ scope.row[item.field_alias] }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          :page-sizes="[15, 30, 40, 50]"
          :page-size="15"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="assignVisiable"
      width="30%"
      title="转让会员"
      :destroy-on-close="true"
      :before-close="cancelAssign"
    >
      <div>
        <el-form :model="follow_form" :rules="follow_rules" ref="follow_form">
          <el-form-item label="跟进人" label-width="100px">
            <el-select
              prop="follow_store_user_id"
              v-model="follow_form.follow_store_user_id"
              placeholder="请选择跟进人"
            >
              <template v-for="(item, index) in selectUserList">
                <el-option
                  :label="item.realname"
                  :value="item.store_user_id"
                  :key="index"
                  >{{ item.realname }}（已分配{{
                    item.follow_num
                  }}位）</el-option
                >
              </template>
            </el-select>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="primary" @click="sureAssignUser('follow_form')"
              >确定</el-button
            >
            <el-button @click="cancelAssign">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="addAssignVisiable"
      width="30%"
      title="添加协助人"
      :destroy-on-close="true"
      :before-close="cancelAddAssign"
    >
      <div>
        <el-form :model="assign_form" :rules="assign_rules" ref="assign_form">
          <el-form-item label="协助人" label-width="100px">
            <el-select
              prop="follow_store_user_id"
              v-model="assign_form.follow_store_user_id"
              placeholder="请选择协助人"
              multiple
              :multiple-limit="6"
            >
              <template v-for="(item, index) in selectUserList">
                <el-option
                  :label="item.realname"
                  :value="item.store_user_id"
                  :key="index"
                  >{{ item.realname }}</el-option
                >
              </template>
            </el-select>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="primary" @click="addAssignUser('assign_form')"
              >确定</el-button
            >
            <el-button @click="cancelAddAssign">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="setIntVisiable"
      width="30%"
      title="设置积分"
      :destroy-on-close="true"
      :before-close="cancelSetInt"
    >
      <el-form :model="setIntForm" :rules="intRules" ref="setIntForm">
        <div class="form">
          <el-form-item
            label="操作类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="setIntForm.type">
              <el-radio :label="1">增加</el-radio>
              <el-radio :label="2">扣减</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="积分值"
            :label-width="formLabelWidth"
            prop="integral"
          >
            <el-input
              v-model="setIntForm.integral"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入积分值"
            ></el-input>
            （最多可输入四位小数）
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="setIntForm.remarks"
              type="textarea"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center">
          <el-button
            type="primary"
            @click="submitIntegral('setIntForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelSetInt">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="setSavingsVisiable"
      width="30%"
      title="储值金充值"
      :destroy-on-close="true"
      :before-close="cancelSetSavings"
    >
      <el-form :model="setSavingForm" :rules="savingRules" ref="setSavingForm">
        <div class="form">
          <el-form-item
            label="操作类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="setSavingForm.type">
              <el-radio :label="10">增加</el-radio>
              <el-radio :label="20">扣减</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="储值金"
            :label-width="formLabelWidth"
            prop="give_money"
          >
            <el-input
              v-model="setSavingForm.give_money"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入储值金金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="setSavingForm.remark"
              type="textarea"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center">
          <el-button
            type="primary"
            @click="submitSavings('setSavingForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelSetSavings">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <memberDetail
      :showCoupon="showCoupon"
      v-if="isDetails"
      :client_user_id="user_id"
        :client_user_master_id="client_user_master_id"
        :hierarchy_id='hierarchy_id'
        
      @hide="getList"
      memberType="assignedMember"
    />
  </div>
</template>

<script>
import {
  assignedUserList,
  assignedUserExport,
  assignedUserTransfer,
  assignedUserFollow,
  assignedUserDischarge,
  getFollowStoreUser,
  setIntegral,
  insertStorage,
} from "@/api/member/memberList/index.js";
import SearchConf from "@/components/searchConf/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import ExportConf from "@/components/exportConf/Index.vue";
import Search from "@/components/search/Index.vue";
import memberDetail from "@/views/member/memberManage/memberDetail/Index.vue";
import Send from "../../components/Send";

export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^\d+(.\d{1,4})?$/.test(value)) {
        callback(new Error("请输入正整数，最多四位小数"));
      } else {
        callback();
      }
    };
    return {
      showCoupon: true,
      user_id: 0,
      isDetails: false,
      exportLoading: false,
      tableData: [],
      rowList: [],
      total: 0,
      page: 1,
      limit: 15,
      cond: {},
      client_user_id: [],
      client_user_master_ids:[],
      tableLoading: false,
      assignVisiable: false,
      follow_form: {},

      selectUserList: [],
      follow_rules: {
        follow_store_user_id: [{ required: true, message: "请选择人员" }],
      },
      assign_rules: {
        follow_store_user_id: [{ required: true, message: "请选择人员" }],
      },
      addAssignVisiable: false,
      formLabelWidth: "100px",
      loading: false,
      assign_form: {},
      loading: false,
      setIntVisiable: false,
      setSavingsVisiable: false,
      setIntForm: {},
      setSavingForm: {},
      intRules: {
        type: [
          { required: true, message: "请选择操作类型", trigger: "change" },
        ],
        integral: [
          { required: true, message: "请填写积分值", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
      savingRules: {
        type: [
          { required: true, message: "请选择操作类型", trigger: "change" },
        ],
        give_money: [
          { required: true, message: "请完善储值金金额", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  components: {
    SearchConf,
    TableConf,
    ExportConf,
    Search,
    memberDetail,
    Send,
  },
  methods: {
    handleDalclick(row) {
      // this.user_id = row.client_user_id;
      // this.isDetails = true;
      this.isDetails = true;
      this.user_id = row.client_user_id;
      this.client_user_master_id=row.client_user_master_id
      this.hierarchy_id=row.hierarchy_id;   
    },
    /*
     *  获取跟进人数据
     */
    selectUser() {
      const data = {
        status: 10,
      };
      getFollowStoreUser(data).then((res) => {
        if (res.code === 1) {
          this.selectUserList = res.data;
        }
      });
    },
    cancelAddAssign() {
      this.addAssignVisiable = false;
      this.assign_form = {};
      this.client_user_id = [];
      this.getList();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList.filter((item) => item.field_alias !== "avatar");
    },
    handleSetSearch() {},
    handleAssistMember() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
        this.actionType = null;
      } else {
        this.addAssignVisiable = true;
        this.selectUser();
      }
    },
    /*
     *  积分设置
     */
    setIntegral() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.setIntVisiable = true;
      }
    },
    /*
     *  关闭积分设置弹窗
     */
    cancelSetInt() {
      this.setIntVisiable = false;
      this.setIntForm = {};
      this.client_user_id = [];
      this.getList();
    },
    /*
     *  积分设置
     */
    submitIntegral() {
      this.$refs.setIntForm.validate((valid) => {
        if (valid) {
          const data = this.setIntForm;
          data.client_user_id = this.client_user_id;
          setIntegral(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "积分设置成功!",
              });
              this.cancelSetInt();
            }
          });
        }
      });
    },
    /*
     *  关闭储值金充值弹窗
     */
    cancelSetSavings() {
      this.setSavingsVisiable = false;
      this.setSavingForm = {};
      this.client_user_id = [];
      this.getList();
    },
    /*
     *  储值金充值
     */
    setSavings() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.setSavingsVisiable = true;
      }
    },
    /*
     *  储值金充值
     */
    submitSavings() {
      this.$refs.setSavingForm.validate((valid) => {
        if (valid) {
          const data = this.setSavingForm;
          data.client_user_id = JSON.stringify(this.client_user_id);
          insertStorage(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "储值金充值成功!",
              });
              this.cancelSetSavings();
            }
          });
        }
      });
    },
    /*
     *  导出
     */
    handleExport() {
      if (JSON.stringify(this.cond) === "{}") {
        this.cond = {
          page: 1,
          limit: this.total,
        };
      }
      const data = {
        ...this.cond,
        function_code: "clientuser",
      };
      this.exportLoading = true;
      assignedUserExport(data)
        .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `已分配会员列表导出.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
          this.exportLoading = false;
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.exportLoading = false;
        });
    },
    /*
     *  放回公海
     */
    handleBackCommon() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
        this.actionType = null;
      } else {
        this.$confirm("是否确定将已选会员放回公海会员中", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            assignedUserDischarge({
              arr_client_user_id: this.client_user_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                  this.actionType = null;
                  this.client_user_id = [];
                  this.getList();
                } else {
                  this.client_user_id = [];
                  this.getList();
                }
              })
              .catch(() => {
                this.client_user_id = [];
                this.getList();
              });
          })
          .catch(() => {
            this.client_user_id = [];
            this.getList();
          });
      }
    },
    handleTransfer() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
        this.actionType = null;
      } else {
        this.assignVisiable = true;
        this.selectUser();
      }
    },
    /*
     *  转让会员
     */
    sureAssignUser() {
      this.$refs.follow_form.validate((valid) => {
        if (valid) {
          const data = {
            arr_client_user_id: this.client_user_id,
            follow_store_user_id: this.follow_form.follow_store_user_id,
          };
          assignedUserTransfer(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.cancelAssign();
              this.getList();
            }
          });
        }
      });
    },
    cancelAssign() {
      this.follow_form = {};
      this.assignVisiable = false;
      this.client_user_id = [];
      this.getList();
    },
    /*
     *  添加协助
     */
    addAssignUser() {
      this.$refs.assign_form.validate((valid) => {
        if (valid) {
          const data = {
            arr_client_user_id: this.client_user_id,
            assis_store_user_id: this.assign_form.follow_store_user_id,
          };
          console.log(data);
          assignedUserFollow(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.cancelAddAssign();
              this.getList();
            }
          });
        }
      });
    },
    getList() {
      this.isDetails = false;
      const data = {
        ...this.cond,
        page: this.page,
        limit: this.limit,
      };
      assignedUserList(data).then((res) => {
        if (res.code === 1) {
          this.tableLoading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    
    handleSelectionChange(val) {
      let arr = [];
      val.forEach((item) => {
        arr.push(item.client_user_id);
      });
      this.client_user_id = [...new Set(arr)];
      console.log(this.client_user_id);
       let arr2=[];
      val.forEach((item) => {
        arr2.push(item.client_user_master_id);
      });
      this.client_user_master_ids = [...new Set(arr2)];
    },

    handleCond(cond) {
      this.cond = { ...cond };
      this.page = 1;
      this.getList();
    },
    handleDetails(event, row) {
      this.user_id = row.client_user_id;
      if (event === 1) {
        this.isDetails = true;
        this.client_user_master_id=row.client_user_master_id
        this.hierarchy_id=row.hierarchy_id;  
      }
    },
  },
};
</script>
